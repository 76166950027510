import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Pagination,
} from 'mw-style-react';
// import cn from 'classnames';
import { withPrefix } from 'gatsby-link';
import { injectIntl } from 'react-intl';
import { navigate } from 'gatsby';
import Utils from '../../utils/utils';
import './Blog.scss';
import PostItem from '../../components/PostItem';
import withIntl from '../../i18n/withIntl';
import Layout from '../../components/Layout/Layout';
import Link from '../../i18n/Link';

class BlogPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.setLinks();
  }

  setLinks() {
    if (typeof window !== 'undefined') {
      const url = `${window.origin}/${this.props.pageContext.locale}/blog/post`;
      const { page, postsOnPage, length } = this.props.pageContext;
      const countPage = Math.ceil(length / postsOnPage);
      if (page !== 1) {
        Utils.addTag('meta', {
          name: 'robots',
          content: 'noindex, follow',
        });
      }

      if (page + 1 <= countPage) {
        Utils.addTag('link',
          {
            rel: 'next',
            href: `${url}/${page + 1}`
          }
        );
      }
      if (page - 1 > 0) {
        Utils.addTag('link',
          {
            rel: 'prev',
            href: `${url}/${page - 1}`
          }
        );
      }
    }
  }

  deleteTags() {
    Utils.removeTags({
      tag: 'link',
      rel: ['next', 'prev'],
    });

    Utils.removeTags({
      tag: 'meta',
      content: 'noindex, follow'
    });
  }

  renderPostItems(posts, intl) {
    return (
      posts.map((post, index) => (
        <PostItem
          key={index}
          data={post.node}
          type="blog"
          lang={intl.locale}
        />
      ))
    );
  }

  changePage = ({value}) => {
    let newUrl = withPrefix(`${this.props.pageContext.locale}/blog/page/${value}`);
    if (value === 1) {
      newUrl = withPrefix(`${this.props.pageContext.locale}/blog`);
    }
    navigate(newUrl);
  };

  componentWillUnmount() {
    this.deleteTags();
  }

  render() {
    const { pageContext, intl } = this.props;
    const { page, length, postsOnPage, posts, tag } = pageContext;

    return (
      <Layout page={'blog'} {...this.props}>
        <section className="blog">
          <div className="blog__header__top"/>
          <div className="blog__wrapper">
            <div className="blog__header">
              <div
                className="blog__title"
              >
                <h1>{intl.formatMessage({id: 'resourceBlogTitle'})}</h1>
                {tag &&
                  <div className="blog__tag">
                    <div className="blog__tag__text">
                      {`${tag.toLowerCase()}`}
                    </div>
                    <Link to={'/blog'} className="blog__tag__icon">
                      <Icon type={'close'} size={'micro'} color={'#fff'}/>
                    </Link>
                  </div>
                }
              </div>
              {/* <Label*/}
                {/* value={intl.formatMessage({id: 'blogSubTitle'})}*/}
                {/* className="blog__subTitle"*/}
              {/* />*/}
              {/* {this.renderFilter(filter, intl)}*/}
            </div>
            <div className="blog__content">
              {this.renderPostItems(posts, intl)}
            </div>
            <div className="blog__footer">
              <Pagination
                className="blog__footer__pagination"
                size={length}
                value={page}
                limit={postsOnPage}
                label={'{range} of {size} posts'}
                onChange={this.changePage}
              />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

BlogPage.propTypes = {
  pageContext: PropTypes.object,
  intl: PropTypes.object,
};

export default withIntl(injectIntl(BlogPage));

